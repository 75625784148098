// **********************************************
// VARIABLES AND MIXINS
// **********************************************
$blauw: rgb(64,180,230);
$grijs: rgb(91,102,112);

// font-sizes for poster quotes
$quote-small: 3rem;
$quote-medium: 5rem;
$quote-large: 7.5rem;

@mixin button {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: rem-calc(14);
  padding-top: rem-calc(13);
}

// color tint function
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

// **********************************************
// FONTS AND BASICS
// **********************************************
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i|Roboto:400,400i,700,900');

a:focus {outline:0;}

h1,h2,h3{
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  line-height: .85;
}

p{
  font-family: 'Lora', serif;
  color: $grijs;
}

.bg-blauw{background-color: $blauw; color: white;}
.bg-grijs{background-color: $grijs; color: white;}
// .bg-white{background-color: none; border: 1px solid white; color: white;}
a.bg-blauw, a.bg-grijs{
  img{
    display: inline;
    padding: 0;
    margin: 0;
    max-width: 15px;
    margin: rem-calc(-2 10 0 0);
  }
}
.text-blauw{color: $blauw;}
.text-grijs{color: $grijs;}


a.bg-blauw, a.bg-blauw:hover, a.bg-blauw:active, a.bg-blauw:visited{
  background-color: $blauw;
  margin-right: rem-calc(10); //margin right for double button spacing
}

a.bg-grijs, a.bg-grijs:hover, a.bg-grijs:active, a.bg-grijs:visited{
  background-color: $grijs;
}

.button{
  @include button;
    @include breakpoint(small only){
      width: 100%; // expanded button on small screens
    }
}

// **********************************************
// QUOTE CONTAINERS
// **********************************************
.quote-container{
  padding: rem-calc(65 10);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__image{
    padding: rem-calc(25 0);
    &--medium{
      max-width: 70%;
      @include breakpoint(small only){
        display: none;
      }
    }
    &--small{
      max-width: 70%;
      @include breakpoint(medium){
        display: none;
      }
    }
    &--all{
      max-width: 55%;
    }
  }
  &__quote--big{
    h1{
      @include breakpoint(small){
        font-size: $quote-small;
      }
      @include breakpoint(medium){
        font-size: $quote-medium;
      }
      @include breakpoint(large){
        font-size: $quote-large;
      }
    }
  }
  &__quote--small{
    h2{
      // font-size: $fontsize-small;
    }
    a{
      color: $grijs;
      &:active, &:hover{
        color: $grijs;
      }
    }
  }
}

// **********************************************
// HERO PIET ZONNEVELD
// **********************************************
.hero{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  width: 100%;
  min-height: 80vh;
  background-image: url('/assets/img/piet.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f2f2f2;
  background-blend-mode: multiply;
  @include breakpoint(small only){
    background-size: contain;
    min-height: 50vh;
  }
  &__outtro{
    padding: rem-calc(0 0 15 0);
    color: $grijs;
    @include breakpoint(small only){
    padding: rem-calc(0);
    }
  }
}

// **********************************************
// TEXT ARTICLES
// **********************************************
article{
  padding: rem-calc(50 25);
}

article.registratie{
  padding: rem-calc(25);
}

aside{
  padding: rem-calc(10 25 50 25);
}

// **********************************************
// INTRO ANIMATION
// **********************************************
#intro-animation{
  @include breakpoint(small){
    padding: rem-calc(60 20 20 20);
  }
  @include breakpoint(medium){
    padding: rem-calc(100);
  }
  @include breakpoint(large){
    padding: rem-calc(175);
  }
}

svg:not(:root){
  overflow: visible;
}

.lttrGroup, .sloganSVG{
  opacity: 0;
}

.sloganSVG{
  transform: translateY(40px);
}

// **********************************************
// SINGLE TEXTPAGE STYLES
// **********************************************
.single{
  h1{
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    text-transform: none;
    color: $blauw;
    font-size: rem-calc(21);
    line-height: 1.2;
  }
  h2{
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-style: italic;
    text-transform: none;
    color: $blauw;
    font-size: rem-calc(18);
    line-height: 1.2;
  }
  li{
    margin-top: rem-calc(10);
  }
  tr{
    color: $grijs;
  }
}

.bedrijven{
  h1, h2{
    color: $grijs;
  }
  b{
    color: tint($grijs, 30%);
  }
}

// accordion styles
.accordion{
  background: tint(black, 95%);
  a{
    font-style: normal;
    font-weight: 600;
    border: none;
    &:hover, &:focus{
      background: inherit;
    }
  }
  &-content{
    font-style: normal;
  }
  li a{
    font-weight: 400;
    font-style: italic;
  }
}

// tabs panel styles
#particulier{
  padding: rem-calc(75 0);
}

#particulier-tabs{
  @include breakpoint(small only){
      padding: rem-calc(25);
      background-color: tint($blauw, 75%);
      h1{
        color: $blauw!important;
      }
  }
  @include breakpoint(medium){
    padding-right: 20%;
  }
  @include breakpoint(large){
    padding-right: 40%;
  }
  h1{
    font-size: rem-calc(20);
    color: tint($grijs, 80%);
    text-transform: uppercase;
    font-weight: 900;
  }
  li{
    font-family: 'Lora', serif;
    font-style: italic;
    transition: all .3s ease-out;
    &:hover{
      transform: translateX(8px);
    }
  }

  .tabsBorder{
      border-top: 2px dotted tint($grijs, 80%);
      margin-top: rem-calc(30);
      padding-top: 20px;
      @include breakpoint(small only){
        border-top: 2px dotted $grijs;
      }
  }
}

.panel0v-hide{
  display: none!important;
}

.tabs-panel{
  li{
    font-style: italic;
    color: $grijs;
  }
  .tabsCategorie{
    display: block;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-size: rem-calc(14);
    color: tint($grijs, 75%);
  }
}

// **********************************************
// BOTTOM NAVIGATION STYLES
// **********************************************
.bottom-nav{
  display: flex;
  justify-content: space-between;
  @include breakpoint(small only){
    flex-direction: column;
  }
  &__back{
    margin-top: rem-calc(-1);
    @include button;
    color: $grijs;
    cursor: pointer;
    @include breakpoint(small only){
      margin-bottom: rem-calc(25);
    }
  }
  img{
    max-width: rem-calc(20);
    margin: rem-calc(0 2 2 0);
  }
}

// **********************************************
// MAIN MENU STYLES
// **********************************************
#main-menu{
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: calc(100vh - 70px);
  background-color: $grijs;
  display: none;
}

.sticky-container{
  z-index: 2;
}

.main-menu{
  position: relative;
  // display: flex;
  width: 100%;
  height: calc(100vh - 70px);
  // align-items: center;
  // justify-content: flex-end;
  @include breakpoint(small only){
    align-items: flex-end;
    padding-bottom: 20%;
  }
}

.menu{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  text-align: right;
 a, .menu .button{
   color: white;
     // background-color: white;
   margin: rem-calc(10 0);
   // padding: rem-calc(40 80);
   text-transform: uppercase;
   font-family: 'Roboto', sans-serif;
   font-weight: 900;
   @include breakpoint(small){
      font-size: rem-calc(26);
   }
   @include breakpoint(medium){
      font-size: rem-calc(46);
   }
   @include breakpoint(large){
      font-size: rem-calc(56);
   }
   &.active{
     color: $blauw;
   }
 }
 li{
   transition: all .3s ease-out;
   &:hover{
     transform: translateX(-20px);
   }
 }
}

::-moz-selection { /* Code for Firefox */
    color: white;
    background: $blauw;
}

::selection {
    color: white;;
    background: $blauw;
}


.scrollTop{
  margin-right: rem-calc(10);
  img{
    max-height: rem-calc(10);
    margin-right: rem-calc(10);
  }
}



#testimonials{
  margin: rem-calc(20 0 50 0);
}

th{
  background: $blauw;
  h1{
    color: white !important;
    padding-top: 10px;
  }
}

ul.soorten-hulp{
  font-weight: 700;
  color: $grijs;
  p{
    font-weight: 400;
  }
}

.covid{
  background: tint($blauw, 75%);
  padding: 1rem;
  margin: 1rem 0 2rem 0;
  font-style: italic;
}
